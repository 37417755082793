export class MetaTag {
  name: string;
  value: string;
  RDFa: boolean;

  constructor(name: string, value: string, RDFa: boolean) {
    this.name = name;
    this.value = value;
    this.RDFa = RDFa;
  }
}
