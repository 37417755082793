import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { LocalizationService } from '@core/localization/localization.service';
import { MetaTag, OpenGraph } from '@core/models';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private meta: Meta,
    private title: Title,
    private localizationService: LocalizationService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  public init(): void {

    /**
     * Start with default data
    */
    this.setSocialMediaMetaTags();

    /**
     * Reset data when navigation start
    */
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(() => this.setSocialMediaMetaTags());

    /**
     * Set meta if there is sitePage or openGraph in route data
    */
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((data) => {
      const openGraph: Partial<OpenGraph> =
        data?.['page']?.open_graph ||
        this.localizedOpenGraph(data?.['openGraph']);

      if (openGraph) {
        this.setSocialMediaMetaTags(openGraph.title, openGraph.description, openGraph.image);
      }
    });
  }

  public setSocialMediaMetaTags(
    title?: Nullable<string>,
    description?: Nullable<string>,
    image?: Nullable<string>
  ): void {

    title = title
      ? `${this.localizationService.instant('SITE_TITLE')} | ${title}`
      : this.localizationService.instant('SITE_TITLE');

    image =
      image ||
      'https://assets.fiteq.org/teqball/teqball-share-image.jpg';
    description =
      description?.replace(/<[^>]*>/g, '').substring(0, 100) ||
      this.localizationService.instant('SITE_DESCRIPTION');

    this.title.setTitle(title);

    const tags = [
      new MetaTag('description', description, false),
      new MetaTag('og:title', title, true),
      new MetaTag('og:description', description, true),
      new MetaTag('og:image', image, true),
      new MetaTag('og:image:secure_url', image, true),
      new MetaTag('twitter:title', title, false),
      new MetaTag('twitter:description', description, false),
      new MetaTag('twitter:image', image, false),
    ];

    this.updateTags(tags);
  }

  private localizedOpenGraph(openGraph: Partial<OpenGraph>): Partial<OpenGraph> {
    if (openGraph?.title) {
      openGraph.title = this.localizationService.instant(openGraph.title);
    }

    if (openGraph?.description) {
      openGraph.description = this.localizationService.instant(openGraph.description);
    }

    return openGraph;
  }

  private updateTags(tags: MetaTag[]): void {
    tags.forEach(tag => this.meta.updateTag({
      [tag.RDFa ? 'property': 'name']: tag.name,
      content: tag.value 
    }));
  }

}
