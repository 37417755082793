import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APP_CONFIG, AppConfig, ButterResponse } from '@core/models';
import { PageType } from '@app/content/models';
import { LocalizationService } from '@core/localization';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ButterService {
  constructor(
    private httpClient: HttpClient,
    private localization: LocalizationService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  public page(pageType: PageType, slug: string, params?: object): Observable<ButterResponse> {
    return this.httpClient.get<ButterResponse>(`${this.config.butterApiUrl}/pages/${pageType}/${slug}/`, {
      params: {
        ...params,
        locale: this.localization.currentLocale,
        preview: this.config.environmentName?.toLowerCase() === 'prod' ? 0 : 1,
        salt: this.config.butterCMSQueryParam,
      },
    });
  }

  public collection(collection: string, params?: object): Observable<ButterResponse> {
    return this.httpClient.get<ButterResponse>(`${this.config.butterApiUrl}/collections/${collection}/`, {
      params: {
        ...params,
        locale: this.localization.currentLocale,
        preview: this.config.environmentName?.toLowerCase() === 'prod' ? 0 : 1,
        salt: this.config.butterCMSQueryParam,
      },
    });
  }
}
