import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  public static readonly DEFAULT_LOCALE = 'en';

  constructor(
    private readonly translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  get currentLocale(): string {
    return this.translate.currentLang;
  }

  init(): Observable<any> {
    let lang = LocalizationService.DEFAULT_LOCALE;
    console.log(this.document.location);
    if (this.document.location.origin === this.config.russianDomain) {
      lang = 'ru';
    }
    this.translate.setDefaultLang(lang);

    return this.translate.use(lang).pipe(first());
  }

  instant(key: string): string {
    return this.translate.instant(key);
  }

  get(key: string): Observable<string> {
    return this.translate.get(key);
  }

  set(key: string): Observable<any> {
    return this.translate.use(key);
  }
}
