import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '@app/core/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {

  constructor(private httpClient: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {}

  get<T>(
    endpoint: string,
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        },
    params?:
      | HttpParams
      | {
          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        }
  ): Observable<T> {
    return this.httpClient.get<T>(`${this.config.apiUrl}${endpoint}`, { headers, params });
  }

  post<T>(
    endpoint: string,
    body: any,
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        },
    params?:
      | HttpParams
      | {
          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        }
  ): Observable<T> {
    return this.httpClient.post<T>(`${this.config.apiUrl}${endpoint}`, body, { headers, params });
  }

  put<T>(
    endpoint: string,
    body: any,
    headers?:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        },
    params?:
      | HttpParams
      | {
          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        }
  ): Observable<T> {
    return this.httpClient.put<T>(`${this.config.apiUrl}${endpoint}`, body, { headers, params });
  }

}
