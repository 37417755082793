import { Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig, ButterResponse } from '@core/models';

export class ButterTranslateLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient, @Inject(APP_CONFIG) private config: AppConfig) {}

  public getTranslation(lang: string): Observable<any> {
    return this.httpClient
      .get<ButterResponse>(`${this.config.butterApiUrl}/collections/teqball_translations/`, {
        params: {
          locale: lang,
          preview: this.config.environmentName?.toLowerCase() === 'prod' ? 0 : 1,
          salt: this.config.butterCMSQueryParam,
        },
      })
      .pipe(
        map(({ data }) =>
          data.teqball_translations
            .filter((item: any) => Boolean(item.translation))
            .reduce((accumlator: any, value: any) => Object.assign(accumlator, { [value.key]: value.translation }), {})
        ),
        catchError((error) => {
          console.error('Could not find translation file:', error);
          return of({});
        })
      );
  }
}
